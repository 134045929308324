<template>
	<div class="page sg ">
		<div class="select-style">
			<div class="flex">
				<p class="select-style__title fs-14">采集方式：</p>
			</div>

			<van-radio-group v-model="selectType" class="ml-4" @change="changeSelect">
				<pgy-radio name="1" class="mt-4">
					<span class="select-radio">采集群内所有消息</span>
				</pgy-radio>
				<pgy-radio name="0" class="mt-5 " style="align-items: normal">
					<p class="select-radio">采集群内指定消息</p>
					<p class="mt-1  color-gray fs-12">最多可指定采集5个成员</p>
				</pgy-radio>
			</van-radio-group>
			<div class="select-box ml-5" v-show="selectType === '0'">
				<div class="relative  content s1 flex pt-2 pb-2">
					<van-search v-model="likeName" placeholder="搜索" shape="round" class="flex-1" @search="getData()"/>
					<van-button type="primary" plain round size="small" class="mr-3" style="padding: 4px 13px;height: 26px"
					            @click="syncGroup">同步
					</van-button>
				</div>
				<div class="divider s1"></div>
				<van-list v-model:loading="loading"
				          :finished="finished"
				          finished-text=""
				          @load="getData">
					<div class="s1 p-card">
						<no-data v-if="list.length===0 && !loading"
						         title="暂无群成员数据，请同步"
						         :show-button="false"/>
						<van-checkbox-group v-model="teacherWxIds" :max="5">
							<check-box
									v-for="(item,index) in computedList"
									:key="item.memberWxId"
									:name="item.memberWxId"
									class="item"
									:disabled="item.enterpriseUser === 1"
							>
								<img class="avatar" v-lazy="item.headImgUrl">
								<div class="flex-1 column">
									<div class="p1">{{
											item.groupNickName ? item.groupNickName : item.nickName
										}}
									</div>
									<small class="color-gray" v-if="item.enterpriseUser === 1">企微用户</small>
								</div>
							</check-box>
						</van-checkbox-group>
					</div>
				</van-list>
			</div>
		</div>


		<div class="p-footer h-end">
			<button class="btn auto "
			        @click="$router.go(-1)">取消
			</button>
			<button class="btn auto fill ml-3"
			        @click="handleSave(1)">确定
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Dialog, Toast } from 'vant'
import PgyCheckBox from '@/components/PgyCheckBox'
import PgyRadio from '@/components/PgyRadio'
import { ApiQueryChatroomMembers, ApiSetFollowRoomLiveTeacher } from '@/api/FollowLive'
import { ApiSyncChatroomMember } from '@/api/Common'

export default {
	name: 'Index',
	components: { PgyCheckBox, PgyRadio },
	data() {
		return {
			teacherWxIds: [],
			// 采集方式 0 指定 1 所有
			selectType: '1',
			// 是否已经初始化
			alreadyInit: false,
			list: [],
			chatroomId: '',
			chatroomImgUrl: '',
			chatroomName: '',
			pageIndex: 1,
			pageSize: 15,
			loading: false,
			finished: false,
			likeName: '',
			type: false
		}
	},
	computed: {
		...mapGetters(['robotInfo', 'hostingGroupInfo']),
		computedList() {
			return this.list.filter(i => {
        const name = 	i.groupNickName ? i.groupNickName : i.nickName
				return !this.likeName || name.indexOf(this.likeName) > -1
			})
		}
	},
	created() {
		let { chatroomId, selectType, chatroomImgUrl, chatroomName } = this.$route.query
		this.chatroomId = chatroomId
		this.chatroomImgUrl = chatroomImgUrl
		this.chatroomName = chatroomName
		this.selectType = selectType ? selectType : '1'
	},
	methods: {
		// 选择采集方式
		changeSelect(val) {
			if (!this.alreadyInit && val === '0') {
				this.getData()
			} else {
				this.teacherWxIds = []
			}
		},
		getData() {
			const _this = this
			this.alreadyInit = true
			_this.getGroupMember()
		},
		async getGroupMember() {
			this.loading = true
			try {
				const res = await ApiQueryChatroomMembers({
					chatroomId: this.chatroomId,
					robotWxId: this.robotInfo.robotWxId,
					likeName: this.likeName,
					roomId: this.hostingGroupInfo.roomId
				})
				this.list = res
				// 判断当前进入的群是否是之前已经选择过的
				if (this.hostingGroupInfo.teacherChatroomId === this.chatroomId) {
					this.teacherWxIds = res.filter(i => i.isTeacher)
					                       .map(i => i.memberWxId)
				}
				this.loading = false
				this.finished = true
			} catch (e) {
				this.loading = false
				this.finished = true
				console.log('ApiQueryChatroomMembers', e)
			}
		},
		async syncGroup() {
			try {
				this.likeName = ''
				await ApiSyncChatroomMember({
					robotWxId: this.robotInfo.robotWxId,
					chatroomId: this.chatroomId
				})
				this.$Toast('同步成功')
				this.getData()
			} catch (e) {
				console.log(e)
			}
		},
		async handleSave() {
			let teacherInfos = []
			if (this.selectType === '0') {
				if (this.teacherWxIds.length === 0) {
					this.$Toast('请先选择某一项！')
					return
				}
				this.list.forEach(item => {
					this.teacherWxIds.includes(item.memberWxId) &&
					teacherInfos.push({
						teacherWxId: item.memberWxId,
						nickName: item.nickName,
						headImgUrl: item.headImgUrl
					})
				})
			}
			const toast1 = Toast.loading({
				message: '设置中...',
				forbidClick: true,
				loadingType: 'spinner',
				duration: 0
			})
			let param = {
				robotSerialNo: this.robotInfo.robotWxId,
				teacherChatroomId: this.chatroomId,
				routerType: this.selectType,
				roomId: this.hostingGroupInfo.roomId,
				teacherInfos: teacherInfos
			}
			try {
				await ApiSetFollowRoomLiveTeacher({ ...param })
				toast1.clear()
				// 手动设置托管群信息
				this.$store.commit('setHostingGroupInfo', {
					routeType: Number(this.selectType),
					teacherChatroomHead: this.chatroomImgUrl,
					teacherChatroomId: this.chatroomId,
					teacherChatroomName: this.chatroomName,
					teacherInfos: teacherInfos
				})
				await this.$router.replace({
					path: '/followMaterial',
					query: {
						from: 'selectMemberGather'
					}
				})
			} catch (e) {
				toast1.clear()
				console.log('ApiSetFollowRoomLiveTeacher', e)
			}
			/*			Dialog.confirm({
			 confirmButtonText: '确定',
			 message: '更改后，将会被应用到所有采集素材服务的跟发群，是否确定更改？'
			 })
			 .then(async () => {

			 })
			 .catch(() => {
			 // on cancel
			 })*/
		}
	}
}
</script>

<style scoped lang="scss">

.page {
	padding: px(20) px(15) px(60);
	background: #ffffff;

	.select-style {
		.select-style__title {
			height: px(22);
			font-size: px(16);
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 700;
			color: #181818;
			line-height: px(22);
		}

		:deep .select-radio {
			height: 20px;
			font-size: px(14);
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: bold;
			color: #181818;
			line-height: px(20);
		}

		.select-box {
			margin-top: px(18);
			background: #F9F9F9;
		}
	}

	.s1 {
		// margin: 0 px(15);
		margin: 0;

		.item {
			position: relative;
			padding: px(15);
			background: #F8FAFA;

			:deep(.van-checkbox__label) {
				display: flex;
				flex: 1;
				align-items: center;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				left: px(95);
				border-bottom: px(1) rgba(221, 221, 221, 1) dashed;
			}

			.avatar {
				margin-right: px(8);
				width: px(48);
				height: px(48);
				border-radius: 50%;
				overflow: hidden;
				background: #f1f1f1;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.p1 {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-weight: 500;
			}

			.p2 {
				margin-top: px(5);
				color: #999;
				font-size: px(12);
			}
		}

		.no-event {
			pointer-events: none;
		}

		.tips {
			padding: px(15);
			display: flex;
			align-items: center;
			font-size: px(12);
			line-height: px(16);
			color: $color-gray;

			&:before {
				content: "";
				display: block;
				margin-right: px(5);
				width: px(16);
				height: px(16);
				background: url("assets/icon-1.png") no-repeat;
				background-size: contain;
			}
		}
	}

	.p-icon-arrow {
		width: px(16);
		height: px(16);
	}

	.tips {
		padding: px(15);
		display: flex;
		align-items: center;
		font-size: px(12);
		line-height: px(16);
		color: $color-gray;

		&:before {
			content: "";
			display: block;
			margin-right: px(5);
			width: px(16);
			height: px(16);
			background: url("assets/icon-1.png") no-repeat;
			background-size: contain;
		}
	}
}

</style>
<style>

.sg .van-search {
	background-color: unset;
}

.sg .van-search__content {
	background-color: #fff;
}

.sg .van-search .van-cell {
	padding: 0.01rem 0.08rem 0.01rem 0;
}

.sg .van-field__left-icon {
	color: #ccc;
}

.sg .van-button--small {
	height: px(24) !important;
}
</style>
